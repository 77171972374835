<template>
    <div
        class="field-container"
        :class="{
            'field-container-label-top': labelPosition == 'top',
            'field-container-label-before': labelPosition == 'before',
        }"
    >
        <!-- Label -->
        <div
            v-if="label"
            class="w-full field-label"
            :class="{
                'field-label--disabled': disabled,
                'field-label--error': error,
            }"
        >
            <span>{{ label }}</span> <span v-if="required">*</span>
        </div>

        <!-- Input -->
        <div
            class="field-input-container"
            :class="{
                'field-input-container--error': error || validateMessage,
                'field-input-container--disabled': disabled,
            }"
        >
            <img v-if="iconStart" :src="iconStart" class="field-input-icon-start" />
            <input
                ref="inputRef"
                autocomplete="off"
                :type="type"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                :id="id ?? 'id-' + name"
                :name="name"
                class="field-input"
                :class="{
                    'field-input--disabled': disabled,
                    'field-input--readonly': readonly,
                    'field-input--8-xr-margin': iconStart,
                    'field-input--8-xl-margin': iconEnd,
                }"
                :value="modelValue"
                @keypress="validateInput"
                @input="update($event)"
            />
            <img v-if="iconEnd" :src="iconEnd" class="field-input-icon-end" />
        </div>
        <div class="field-input-container--error-message">{{ errorMessage || validateMessage }}</div>
    </div>
</template>

<script>
import { ref } from 'vue';
import '../../../css/field.css';

import useBeep from 'browser-beep';

export default {
    name: 'TextFieldEnh',
    props: {
        label: {
            type: String,
            default: '',
        },
        labelPosition: {
            type: String,
            default: 'top',
        },
        placeholder: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        minLength: {
            type: Number,
            default: 0,
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [String, Number],
        },
        iconStart: {
            type: String,
            default: '',
        },
        iconEnd: {
            type: String,
            default: '',
        },
        inputmode: {
            type: String,
            default: 'text',
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const update = (event) => {
            emit('update:modelValue', event.target.value);
            if (isValidating.value) {
                validate();
            }
        };
        const validateInput = (event) => {
            const { inputmode } = props;
            const char = String.fromCharCode(event.which);

            if (inputmode === 'text') {
                return true;
            }

            if (inputmode === 'decimal') {
                if (!/[0-9.,+-]/.test(char)) {
                    event.preventDefault();
                }
            }

            if (inputmode === 'numeric') {
                if (char == '.' && props.modelValue.includes('.')) {
                    const beep = useBeep();
                    beep(1);
                    event.preventDefault();
                } else if (!/[0-9+-]/.test(char)) {
                    const beep = useBeep();
                    beep(1);
                    event.preventDefault();
                }
            }
        };

        const inputRef = ref(null);
        const isValidating = ref(false);
        const validateMessage = ref('');
        const validate = () => {
            if (props.required && !inputRef.value?.value) {
                validateMessage.value = 'Campo obbligatorio';
                return false;
            }
            if (props.required && props.minLength && inputRef.value?.value.length < props.minLength) {
                validateMessage.value = `Minimo ${props.minLength} caratteri`;
                return false;
            }
            if (!props.required && props.minLength && inputRef.value?.value != '' && inputRef.value?.value.length < props.minLength) {
                validateMessage.value = `Minimo ${props.minLength} caratteri`;
                return false;
            }
            validateMessage.value = '';
            return true;
        };
        const isValid = () => {
            isValidating.value = true;
            return validate();
        };

        return {
            update,
            validateInput,
            validateMessage,
            isValid,
            inputRef,
        };
    },
};
</script>

<style lang="postcss" scoped>
.field-container {
    @apply flex;
}
.field-container-label-top {
    @apply flex-col;
}
.field-container-label-before {
    @apply flex-row;
}
</style>
